body {
    scroll-behavior: smooth;
}

[data-title] {
	font-size: 18px;
	position: relative;
	cursor: help;
}
	
[data-title]:hover::before {
	content: attr(data-title);
	position: absolute;
	bottom: -46px;
	padding: 10px;
	border-radius: 10px;
	background: var(--bs-dark);
	color: var(--bs-light);
	font-size: 14px;
	white-space: nowrap;
}

[data-title]:hover::after {
	content: '';
	position: absolute;
	bottom: -6px;
	left: 18px;
	border: 8px solid transparent;  
	border-bottom: 8px solid var(--bs-dark);
}

legend {
    float: none;
    width: auto;
}

#topbar {
	z-index: 10;
}

.container {
	max-width: 750px;
}

#quickViewLineChart {
	max-height: 500px;
}

.h-100px {
	height: 100px;
}

.h-50px {
	height: 50px;
}

.refresh {
    	-webkit-animation-name: spin;
    	-webkit-animation-duration: 500ms;
    	-webkit-animation-iteration-count: infinite;
    	-webkit-animation-timing-function: linear;
    	-moz-animation-name: spin;
    	-moz-animation-duration: 500ms;
    	-moz-animation-iteration-count: infinite;
    	-moz-animation-timing-function: linear;
    	-ms-animation-name: spin;
    	-ms-animation-duration: 500ms;
    	-ms-animation-iteration-count: infinite;
    	-ms-animation-timing-function: linear;
    
    	animation-name: spin;
    	animation-duration: 500ms;
    	animation-iteration-count: infinite;
    	animation-timing-function: linear;
}

.collapsed i {
        transform: rotate(0deg);
}

i {
        transform: rotate(180deg);
}

.icon {
        transform: rotate(0deg) !important;
}

#accordion_ReportWizard {
    position: sticky;
    z-index: 2;
    top: 54px;
    /* width: 100%; */
    border-radius: 0;
    margin: 0 auto !important;
}

/**************/
/* ANIMATIONS */
/**************/
@-ms-keyframes spin {
    	from { -ms-transform: rotate(0deg); }
    	to { -ms-transform: rotate(360deg); }
}
@-moz-keyframes spin {
    	from { -moz-transform: rotate(0deg); }
    	to { -moz-transform: rotate(360deg); }
}
@-webkit-keyframes spin {
    	from { -webkit-transform: rotate(0deg); }
    	to { -webkit-transform: rotate(360deg); }
}
@keyframes spin {
    	from {
        	transform:rotate(0deg);
    	}
    	to {
        	transform:rotate(360deg);
	}
}
